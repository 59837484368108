import React, { Component, useEffect, useState, useRef } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import $ from "jquery";
import '../styles/StyleSheet.css';
import ReCAPTCHA from "react-google-recaptcha";
import 'react-notifications/lib/notifications.css';
import { NotificationContainer, NotificationManager } from 'react-notifications';

const Home = () => {

    const [solicitud, setSolicitud] = useState([]);
    const [servicio, setServicio] = useState([]);
    const [servicioSelected, setServicioSelected] = useState("");
    const [campo, setCampo] = useState([]);
    const [programa, setPrograma] = useState([]);
    const [finalModal, setModal] = useState(false);
    const [descripcion, setDescripcion] = useState([]);
    const captchaRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);

    const getSolicitudes = async () => {
        const response = await fetch("/api/home/ObtenerTipoSolicitud");
        const data = await response.json();
        setSolicitud(data);
    }

    const fillServicios = async (event) => {
        setServicioSelected("");
        setServicio([]);
        setCampo([]);
        setDescripcion([]);
        const option = event.target.value;

        const rawResponse = await fetch("/api/home/ObtenerServicio", {
            method: 'POST',
            mode: 'cors',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ IdTipoSolicitud: option })
        });
        const deito = await rawResponse.json();
        setServicio(deito);
    }

    const getCampos = async (event) => {
        setServicioSelected(event.target.value);
        setCampo([]);
        const selection = event.target.value;

        const response = await fetch("/api/home/ObtenerCamposServicio", {
            method: 'POST',
            mode: 'cors',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ IdServicio: selection })
        })

        const data = await response.json();
        setCampo(data);
        getDescripServicio(selection);
    }

    const getDescripServicio = async (id) => {

        setDescripcion([]);

        const responseDescrip = await fetch("/api/home/ObtenerDescripcionServicio", {
            method: 'POST',
            mode: 'cors',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ IdServicio: id })
        })

        const descripData = await responseDescrip.json();
        setDescripcion(descripData);
    }

    const getProgramas = async () => {
        const response = await fetch("/api/home/ObtenerProgramas");
        const data = await response.json();
        setPrograma(data);
    }

    const sendInfo = async (e) => {
        let _fields = {};
        let form = document.getElementById('formUAG');
        for (let i = 0; i < form.elements.length; i++) {
            if (form.elements[i].value === '' && form.elements[i].hasAttribute('required')) {
                NotificationManager.warning('Faltan datos por llenar', 'Alerta', 3000);
                return false;
            }
            _fields[form.elements[i].name] = form.elements[i].value;
        }

        e.preventDefault();

        const token = captchaRef.current.getValue();
        captchaRef.current.reset();

        let info = {
            Materia: _fields.txtMateria,
            Programa: _fields.txtPrograma,
            IdEgresado: _fields.IdEgresado,
            NombreEgresado: _fields.NombreEgresado,
            CarreraEgresado: _fields.CarreraEgresado,
            GeneracionEgresado: _fields.GeneracionEgresado,
            SugerenciaUAG: _fields.SugerenciaUAG,
            Domicilio: _fields.Domicilio,
            Ciudad: _fields.Ciudad,
            CodigoPostal: _fields.CodigoPostal,
            Telefono: _fields.Telefono,
            Celular: _fields.Celular,
            TelefonoAdicional: _fields.TelefonoAdicional,
            EmpresaTrabajoAlumno: _fields.EmpresaTrabajoAlumno,
            TelefonoEmpresa: _fields.TelefonoEmpresa,
            PuestoEmpresa: _fields.PuestoEmpresa,
            IngresoMensual: _fields.IngresoMensual,
            HermanosUAGOtros: _fields.HermanosUAGOtros,
            BecaAceptoProaase: _fields.BecaAceptoProaase,
            BecaAceptoFobeca: _fields.BecaAceptoFobeca,
            RedaccionPeticion: _fields.RedaccionPeticion,
            NombrePadreMadre: _fields.NombrePadreMadre
            , DomicilioPadreMadre: _fields.DomicilioPadreMadre
            , OcupacionPadre: _fields.OcupacionPadre
            , FechaNacimientoPadreMadre: _fields.FechaNacimientoPadreMadre
            , RFCPadreMadre: _fields.RFCPadreMadre
            , LugarNacimientoPadreMadre: _fields.LugarNacimientoPadreMadre
            , NacionalidadPadreMadre: _fields.NacionalidadPadreMadre
            , TelefonosCasaPadreMadre: _fields.TelefonosCasaPadreMadre
            , TelefonosOficinaPadreMadre: _fields.TelefonosOficinaPadreMadre
            , TelefonosCelularPadreMadre: _fields.TelefonosCelularPadreMadre
            , TipoDescuento: _fields.TipoDescuento
            , Hijo1: _fields.Hijo1
            , Hijo2: _fields.Hijo2
            , Hijo3: _fields.Hijo3
            , Hijo4: _fields.Hijo4
            , MasHijos: _fields.MasHijos
            , BecaAceptoHermanos: _fields.BecaAceptoHermanos
            , Idioma: _fields.Idioma
            , Periodo: _fields.Periodo
            , BecaAceptoCartaCompromiso: _fields.BecaAceptoCartaCompromiso
            , BecaAceptoCartaCompromisoPadreMadre: _fields.BecaAceptoCartaCompromisoPadreMadre
        }

        if (token === "") {
            NotificationManager.warning('Falta validar captcha', 'Alerta', 3000);
        }
        else {
            document.getElementById('btnSave').disabled = true;
            let form = document.getElementById('formUAG');
            for (let i = 0; i < form.elements.length; i++) {
                if (form.elements[i].value === '' && form.elements[i].hasAttribute('required')) {
                    NotificationManager.warning('Faltan datos por llenar', 'Alerta', 3000);
                    return false;
                }
            }
            setIsLoading(true);
            await fetch("/api/home/CrearRegistro", {
                method: 'POST',
                mode: 'cors',
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    IdTipoSolicitud: _fields.IdTipoSolicitud,
                    IdServicio: _fields.IdServicio,
                    Nombre: _fields.Nombre,
                    Apellidos: _fields.Apellidos,
                    Email: _fields.Email,
                    Asunto: _fields.Asunto,
                    IdUAG: _fields.IdUAG,
                    InfoExtra: info
                })
            })
                .then(data => data.json())
                .then(data => {
                    setIsLoading(false);
                    if (data.message == "Guardado con exito") {
                        setModal(true);
                    } else {
                        NotificationManager.error('No se pudo procesar la solicitud', 'Error', 9000);
                        setIsLoading(false);
                    }
                })
        }
    }

    const Modal = () => {
        return (
            <div id="demo-modal" className="modal-custom" style={{ opacity: 1 }}>
                <div className="modal__content">
                    <h1>¡Hemos recibido tu solicitud!</h1>
                    <p>
                        En breve recibirás un correo con el ID de tu solicitud, para poder darle seguimiento a tu servicio.
                        Si deseas realizar otra solicitud, haz clic en el botón que se presenta a continuación.
                    </p>
                    <div style={{ textAlign: "center" }} className="send">
                        <button class="btn btn-success" onClick={event => remake()}>Quiero realizar otra solicitud</button>
                    </div>
                </div>
            </div>
        )
    }

    const remake = (event) => {
        window.location.href = window.location.href;
    }

    const LoadingModal = () => {
        return (
            <div className="loading-modal">
                <div className="loading-content">
                    <h3>Cargando...</h3>
                </div>
            </div>
        );
    }

    useEffect(() => {
        getSolicitudes();
        getProgramas();
    }, [])

    return (
        <div>
            {isLoading && <LoadingModal />}
            <NotificationContainer />
            <br></br>
            <br></br>
            <div className="card shadow p-3 mb-5 bg-white rounded" style={{ borderTop: 100 }}>
                {finalModal && Modal()}
                <div className="card-body">
                    <h4 className="card-title" style={{ textAlign: 'center' }}>Envíar una solicitud</h4>
                    <hr></hr>
                    <br></br>
                    <form encType="application/x-www-form-urlencoded" method="post" id="formUAG">
                        <div className="form-group">
                            <h6 style={{ textAlign: 'center' }}>¿En qué podemos apoyarte?</h6>
                            <select onChange={fillServicios} className="form-control" id="IdTipoSolicitud" name="IdTipoSolicitud" defaultValue="" required>
                                <option value="" selected disabled hidden>Selecciona una opción</option>
                                {solicitud.map(solicitud =>
                                    <option value={solicitud.idTipoSolicitud}>{solicitud.descripcion}</option>
                                )}
                            </select>
                        </div>
                        <br></br>
                        <div className="form-group">
                            <h6 style={{ textAlign: 'center' }}>Selecciona un Servicio</h6>
                            <select onChange={getCampos} name="IdServicio" id="IdServicio" className="form-control" defaultValue={servicioSelected} required>
                                <option value="" disabled hidden>Selecciona una opción</option>
                                {servicio.map((servicio, index) => {
                                    if (servicio.padre > 0) {
                                        const jsonCampos = JSON.parse(servicio.campos);
                                        return (
                                            <optgroup label={servicio.servicio}>
                                                {jsonCampos.map((servicioCampo, index) =>
                                                    <option key={index} value={servicioCampo.IdServicio}>{servicioCampo.Servicio}</option>
                                                )}
                                            </optgroup>
                                        )
                                    }
                                    else
                                        return <option key={index} value={servicio.idServicio}>{servicio.servicio}</option>
                                }
                                )}
                            </select>
                        </div>
                        <div className="form-group">
                            <br></br>
                            {descripcion.map((descripcion, index) => {
                                return (
                                    <div>
                                        <hr></hr>
                                        <p style={{ textAlign: 'center', whiteSpace: 'pre-line' }}><b>Descripción: </b>{descripcion.descripcion}</p>
                                        <hr></hr>
                                    </div>
                                )
                            })}
                            {campo.map((camposub, index) => {
                                return (
                                    <div>
                                        <br></br>
                                        <h6 style={{ textAlign: 'center' }}>{camposub.titulo}</h6>
                                        <input name={camposub.descripcion} id={camposub.descripcion} required={String(camposub.requerido) === "true"} className="form-control" list={camposub.list}>
                                        </input>
                                        <datalist id="listaProgramas">
                                            {programa.map((programa, index) => {
                                                return <option value={programa.descripProg}>{programa.descripProg}</option>
                                            }
                                            )}
                                        </datalist>
                                    </div>
                                )
                            }
                            )}
                        </div>
                        <br></br>
                        <div className="dntCaptcha" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <ReCAPTCHA sitekey={process.env.REACT_APP_SITE_KEY} ref={captchaRef} />
                        </div>
                        <br></br>
                        <div style={{ textAlign: 'center' }}>
                            <button type="button" className="btn btn-secondary" id="btnSave" onClick={sendInfo} style={{ background: 'rgba(126,33,26,1)' }}>Enviar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Home;